
body {
    background-color: white;
    color: #1d1c1a;
    font-family: 'Gothic A1', sans-serif;
}

h1, h2, h3, h4, h5 {
    font-family: 'Dancing Script', cursive;
    font-size: 70px;
}

p {
    text-align: justify;
    font-family: 'Gothic A1', sans-serif;
}

#etsyStoreTitle {
    font-family: 'Gothic A1', sans-serif;
}

/* Some page wide transitions */
.row {
    transition: all .2s ease-in-out;
    background-color: #f2dab1;
}

.minimalRow {
    padding: 0 !important;
}

.minimalCol {
    padding-left: 0px;
    padding-right: 0px;
}

.highlightRow {
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.alternateRow {
    background-color:#f7e7cc;
} 

.headerBanner {
    background-color: #d2b788;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 450px;

    background: repeating-linear-gradient(
        45deg,
        #f2dab1,
        #f2dab1 20px,
        #d2b788 20px,
        #d2b788 40px
        );

    border-bottom: 3px solid #d2b788;


    /* background: url('/img/banner_01.jpg');
    background-repeat: no-repeat;
    background-size: cover; */
}

.headerBanner .img-fluid {
    max-height: 250px;
}

#footer {
    height: 200px;
    display: block;
    background-color: #3f3f3f;
}

.form-control {
    border: none;
}

.btn-primary {
    background-color: #70644e;
    border-color: #695e49;
    color: white;
}

.btn-primary:hover {
    background-color: #978d79;
    border-color: #7c715c;
}

#contactRow {
    min-height: 300px;
}

#etsyStoreLink {
    width: 300px;
    margin: auto;
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
    cursor: pointer;
}

#wegmansStoreLink {
    width: 360px;
    margin: auto;
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
    cursor: pointer;
}

#etsyStoreLink svg {
    fill: #f45800;
    transition: all .2s ease-in-out;
}

#etsyStoreLink:hover svg {
    fill: #544b3a;
}

#navContainer {
    width: 250px;
    display: block;
    position: absolute;
    top: 10px;
    right: 0px;
}

#contact_send_button {
    width: 150px;
    margin-left: 15px;
}

/* When it drops to mobile size shrink the nav container */
@media only screen and (max-width: 600px) {
    #navContainer {
        width: 150px;
    }

    #contact_send_button {
        width: 93%;
        margin: auto;
        display: block;
    }
}

.btn-nav {
    border-radius: 0px;
    width: 100%;
    float: right;
    height: 40px;
    display: block;
    margin-top: 10px;
    border-right: none;
    border: none;
}
